.tiny-slider {
  opacity: 0;
  transition: opacity 500ms linear;

  &.tiny-slider-initialized {
    opacity: 1;
  }

  .tns-lazy-img {
    opacity: .6;
    transition: opacity 0.6s;
    &.tns-complete {
      opacity: 1;
    }
  }
}

.tns-lazy-img {
  opacity: 1;
  transition: none;
}

.tiny-slider-container {
  min-width: 100%;
  min-height: 100%;
}